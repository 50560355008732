import React from "react"
import { Box } from "rebass"
import { EuiCommentList } from "@elastic/eui/lib/components/comment_list"

const Timeline = () => {
  const comments = [
    {
      username: "Spinup",
      type: "update",
      event: "is founded",
      timestamp: "on January 5th, 2017",
    },
    {
      username: "2017",
      type: "update",
      event: "Spinup has 3 employees",
    },
    {
      username: "2017",
      type: "update",
      event: "turnover of 200.000€",
    },
    {
      username: "2018",
      type: "update",
      event: "turnover of 855.000€",
    },
    {
      username: "Mediabuzz",
      event: "published an article",
      timestamp: "on November 11th, 2019",
      children: (
        <a
          href="https://www.mediabuzz.it/lead-generation-native-advertising-chatbot/"
          target="_blank"
          rel="noopener noreferrer"
        >
          SpinUp, lead generation agency, racconta la sua strategia per
          acquisire nuovi utenti utilizzando canali innovativi
        </a>
      ),
    },
    {
      username: "Spremute Digitali",
      event: "published an article",
      timestamp: "on November 14th, 2019",
      children: (
        <a
          href="https://www.spremutedigitali.com/lead-generation-community-verticali/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Verticalizzare per avere successo con la lead generation. La parola al
          CEO di Spinup
        </a>
      ),
    },
    {
      username: "Corriere della Sera",
      event: "published an article",
      timestamp: "on November 14th, 2019",
      children: (
        <a
          href="http://nuvola.corriere.it/2019/11/14/vogliamo-creare-unacademy-ed-esportarla-allestero/"
          target="_blank"
          rel="noopener noreferrer"
        >
          "Vogliamo creare un'academy al Sud ed esportarla all'estero"
        </a>
      ),
    },
    {
      username: "Engage",
      event: "published an article",
      timestamp: "on November 25th, 2019",
      children: (
        <a
          href="https://www.engage.it/agenzie/spinup-chiusura-2019-sbarco-uk.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Il 2019 di Spinup a oltre 1 milione di fatturato. Ora al lavoro per lo
          sbarco in UK
        </a>
      ),
    },
    {
      username: "Startup Magazine",
      event: "published an article",
      timestamp: "on December 12th, 2019",
      children: (
        <a
          href="https://www.startupmagazine.it/unacademy-per-formare-i-marketers-del-futuro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Un'academy per formare i marketers del futuro
        </a>
      ),
    },
    {
      username: "2019",
      type: "update",
      event: "turnover of 1.450.000€",
    },
    {
      username: "2019",
      type: "update",
      event: "Spinup has 8 employees",
    },
    {
      username: "Forbes",
      event: "published an article",
      timestamp: "on January 6th, 2020",
      children: (
        <a
          href="https://www.forbes.fr/mediasfrance/spinup-lacteur-italien-qui-metamorphose-la-recherche-de-prospects/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup, l'acteur italien qui métamorphose la recherche de prospects
        </a>
      ),
    },
    {
      username: "Adnkronos",
      event: "published an article",
      timestamp: "on February 8th, 2020",
      children: (
        <a
          href="https://www.adnkronos.com/lavoro/cerco-lavoro/2020/02/06/spinup-academy-digital-economy-sud-contro-fuga-dei-cervelli_7xxXbPmNxf4ZDz3rdEaovK.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup, academy digital economy al Sud contro la fuga dei cervelli
        </a>
      ),
    },
    {
      username: "Bitmat",
      event: "published an article",
      timestamp: "on May 19th, 2020",
      children: (
        <a
          href="https://www.bitmat.it/blog/news/97161/tiscali-sceglie-spinup-per-le-campagne-di-conversational-marketing"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tiscali sceglie Spinup per le campagne di conversational marketing
        </a>
      ),
    },
    {
      username: "CMI",
      event: "published an article",
      timestamp: "on May 21st, 2020",
      children: (
        <a
          href="https://www.cmimagazine.it/22075-spinup-e-stata-scelta-da-tiscali-per-le-sue-campagne-di-conversational-marketing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup è stata scelta da Tiscali per le sue campagne di conversational
          marketing
        </a>
      ),
    },
    {
      username: "Youmark",
      event: "published an article",
      timestamp: "on May 27th, 2020",
      children: (
        <a
          href="https://youmark.it/ym-interactive/prima-assicurazioni-sceglie-spinup-per-le-campagne-di-conversational-marketing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Prima Assicurazioni sceglie Spinup per le campagne di conversational
          marketing
        </a>
      ),
    },
    {
      username: "Spinup",
      type: "update",
      event: "acquires Rewave and becomes the first Italian media tech company",
      timestamp: "on June 25th, 2020",
    },
    {
      username: "2020",
      type: "update",
      event: "Spinup has 13 employees",
    },
    {
      username: "Digitude",
      event: "published an article",
      timestamp: "on August 1st, 2020",
      children: (
        <a
          href="https://www.digitude.it/la-storia-di-antonio-romano-founder-di-spinup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          La storia di Antonio Romano, founder di Spinup
        </a>
      ),
    },
    {
      username: "Engage",
      event: "published an article",
      timestamp: "on September 7th, 2020",
      children: (
        <a
          href="https://www.engage.it/agenzie/spinup-acquisisce-rewave-e-diventa-una-media-tech-company.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup acquisisce Rewave e diventa una media tech company
        </a>
      ),
    },
    {
      username: "Millionaire",
      event: "published an article",
      timestamp: "on September 7th, 2020",
      children: (
        <a
          href="https://www.millionaire.it/spinup-la-prima-media-tech-company-italiana-del-performance-marketing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup, la prima media tech company italiana del performance marketing
        </a>
      ),
    },
    {
      username: "Forbes",
      event: "published an article",
      timestamp: "on September 8th, 2020",
      children: (
        <a
          href="https://forbes.it/2020/09/08/spinup-la-prima-media-tech-company-italiana-cosa-e-come-funziona/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup: la prima media tech company italiana
        </a>
      ),
    },
    {
      username: "Il Fatto Quotidiano",
      event: "published an article",
      timestamp: "on September 24th, 2020",
      children: (
        <a
          href="https://www.ilfattoquotidiano.it/2020/09/24/sono-stato-allestero-e-a-milano-poi-sono-tornato-a-napoli-ora-ho-la-mia-azienda-e-voglio-investire-qui/5933269/"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Sono stato all’estero e a Milano, poi sono tornato a Napoli: ora ho
          la mia azienda e voglio investire qui”
        </a>
      ),
    },
    {
      username: "Radio 24",
      event: "published an episode",
      timestamp: "on November 2nd, 2020",
      children: (
        <a
          href="https://www.radio24.ilsole24ore.com/programmi/focus-economia/puntata/trasmissione-02-novembre-2020-183251-AD2Y2mz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup: Intervista ad Antonio Romano
        </a>
      ),
    },
    {
      username: "Rai Italia",
      event: "published a service",
      timestamp: "on November 3rd, 2020",
      children: (
        <a
          href="https://www.raiplay.it/video/2020/11/L-Italia-con-voi-del-03-11-2020-8a78a8bd-7326-4cab-b1e6-9f546885b72a.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup: L'italia con voi
        </a>
      ),
    },
    {
      username: "ANSA",
      event: "published an article",
      timestamp: "on November 13th, 2020",
      children: (
        <a
          href="https://www.ansa.it/sito/notizie/economia/2020/11/13/spinup-raddoppia-ricavi-nove-mesi-stima-crescita-in-2021_8341ce81-403e-48f5-8211-e154ff5ee809.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup: raddoppia ricavi nove mesi, stima crescita in 2021
        </a>
      ),
    },
    {
      username: "Engage",
      event: "published an article",
      timestamp: "on November 13th, 2020",
      children: (
        <a
          href="https://www.engage.it/agenzie/spinup-ricavi-in-forte-crescita-nel-2020-e-nuovi-prodotti-tecnologici-in-arrivo-.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup, ricavi in forte crescita nel 2020 e nuovi prodotti tecnologici
          in arrivo
        </a>
      ),
    },
    {
      username: "Milano Finanza",
      event: "published an article",
      timestamp: "on November 13th, 2020",
      children: (
        <a
          href="https://www.milanofinanza.it/news/ricerca-mfdj?testo=spinup+ricavi+9+mesi+piu+che+raddoppiati"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup: ricavi 9 mesi piu' che raddoppiati
        </a>
      ),
    },
    {
      username: "Il Denaro",
      event: "published an article",
      timestamp: "on December 9th, 2020",
      children: (
        <a
          href="https://www.ildenaro.it/spinup-lancia-brain-nasce-il-marketer-2-0-gli-algoritmi-gestiscono-le-campagne-i-manager-le-strategie/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup lancia Brain: nasce il marketer 2.0. Gli algoritmi gestiscono
          le campagne, i manager le strategie
        </a>
      ),
    },
    {
      username: "Il Sole 24 Ore (printed version)",
      event: "published an article",
      timestamp: "on December 11th, 2020",
      children: (
        <a
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/sole_24_ore_11_12_2020.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          La crescita di Spinup: il marketing digitale raddoppia il fatturato
        </a>
      ),
    },
    {
      username: "Il Sole 24 Ore",
      event: "published an article",
      timestamp: "on December 11th, 2020",
      children: (
        <a
          href="https://www.ilsole24ore.com/art/la-crescita-spinup-marketing-digitale-raddoppia-fatturato-ADC6yg5"
          target="_blank"
          rel="noopener noreferrer"
        >
          La crescita di Spinup: il marketing digitale raddoppia il fatturato
        </a>
      ),
    },
    {
      username: "Milano Finanza (printed version)",
      event: "published an article",
      timestamp: "on December 19th, 2020",
      children: (
        <a
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/milano_finanza_19_dec.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Il performance marketing di Spinup
        </a>
      ),
    },
    {
      username: "2021",
      type: "update",
      event: "turnover of 3.600.000€",
    },
    {
      username: "Economy Magazine",
      event: "published an article",
      timestamp: "on February 25th, 2021",
      children: (
        <a
          href="https://www.economymagazine.it/news/2021/02/25/news/il-performance-marketing-entra-nellera-del-b2a-business-to-algorithm-38650/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Il performance marketing entra nell’era del B2A (business to
          algorithm)
        </a>
      ),
    },
    {
      username: "Economy Magazine (printed version)",
      event: "published an article",
      timestamp: "on March 11th, 2021",
      children: (
        <a
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/la_redenzione_arriva_da_facebook.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          La «redenzione» arriva da Facebook
        </a>
      ),
    },
    {
      username: "MondoMobileWeb",
      event: "published an article",
      timestamp: "on June 16th, 2021",
      children: (
        <a
          href="https://www.mondomobileweb.it/209169-linkem-spinup-partnership-con-hyper-local-ads/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkem e Spinup rafforzano la loro partnership con lo strumento Hyper
          Local Ads
        </a>
      ),
    },
    {
      username: "Il Messaggero (printed version)",
      event: "published an article",
      timestamp: "on June 18th, 2021",
      children: (
        <a
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/20210618_547352714.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkem - Spinup rafforza l'alleanza
        </a>
      ),
    },
    {
      username: "Techprincess",
      event: "published an article",
      timestamp: "on June 22nd, 2021",
      children: (
        <a
          href="https://techprincess.it/linkem-spinup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spinup rafforza la partnership con Linkem
        </a>
      ),
    },
    {
      username: "Il Sole 24 Ore",
      event: "published an article",
      timestamp: "on November 18th, 2021",
      children: (
        <a
          href="https://www.ilsole24ore.com/art/campagne-pochi-minuti-e-ascolto-clienti-conquistare-social-AEbSZOu?refresh_ce=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Campagne in pochi minuti e ascolto dei clienti per conquistare i
          social
        </a>
      ),
    },
  ]

  return (
    <Box mt={[7, 8, 9]}>
      <EuiCommentList comments={comments} />
    </Box>
  )
}

export default Timeline
